import React from 'react'
import HomeHeaderLayout from './home-header.styles'

const HomeHeader = () => (
  <HomeHeaderLayout>
    <HomeHeaderLayout.homeHeaderContainer>
      <HomeHeaderLayout.title>
        Everything You Need to Get Online Today
      </HomeHeaderLayout.title>
      <HomeHeaderLayout.subtitle>
        We give you all the tools you need to create your website today. Enjoy
        the freedom to drag-and- drop your content and images into your chosen
        template and build your dream site.
      </HomeHeaderLayout.subtitle>
    </HomeHeaderLayout.homeHeaderContainer>
  </HomeHeaderLayout>
)

export default HomeHeader
