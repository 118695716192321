import React, { useState } from 'react'
import { DataElementLocations } from '@eig-builder/core-utils/helpers/tagging-helper'
import HomeHeroLayout from './home-hero.styles'
import PopPup from '../../pop-pup'

const HomeHero = () => {
  const [callToAction, setCallToAction] = useState(false)
  const redirectWebdotcom = (websiteUrl) => {
    setTimeout(() => {
      document.location.href = websiteUrl
      setCallToAction(false)
  }, 5000)
  }
  return (
    <HomeHeroLayout>
      <HomeHeroLayout.content>
        <HomeHeroLayout.title>
          Build Your Own
          <br />
          Professional Website
        </HomeHeroLayout.title>
        <HomeHeroLayout.button
          variant='outlined'
          data-element-location={DataElementLocations.HERO}
          data-element-label='home.hero.button'
          data-element-id='sitey-home-hero-button'
          onClick={() => {
            setCallToAction(true)
            redirectWebdotcom('https://www.web.com/websites/online-store-builder')
          }}
        >
          Get Started
        </HomeHeroLayout.button>
        <HomeHeroLayout.popUpContainer>
          <PopPup callToAction={callToAction} setCallToAction={setCallToAction} />
        </HomeHeroLayout.popUpContainer>
      </HomeHeroLayout.content>
    </HomeHeroLayout>
  )
}

export default HomeHero
