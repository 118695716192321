import styled from 'styled-components'
import { theme, media } from '../../../helpers/theme'
import HomeHeroImage from '../../../images/home-hero.jpg'
import Button from '@eig-builder/core-ui/Button'

const HomeHero = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  background-image: url(${HomeHeroImage});
  background-repeat: no-repeat;
  background-color: ${theme.colors.darkBlue};
  width: 100%;
  justify-content: flex-start;
  background-size: auto 90%;
  background-position: calc(50% - 9rem) 100%;
  height: 600px;

  ${media('lg')} {
    background-position-y: 100%;
    background-position-x: 40%;
    background-size: unset;
    height: 500px;
  }

  ${media('xl')} {
    background-position-x: 20%;
  }

  ${media('2xl')} {
    background-position-x: -50%;
  }

  ${media(1600)} {
    background-position-x: 100%;
  }
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  color: ${theme.colors.white};
  margin: auto;
  margin-top: ${theme.space[24]}px;
  text-align: center;
  padding: 0 ${theme.space[4]}px;

  ${media('lg')} {
    margin: auto;
    text-align: unset;
  }

  ${media(768)} {
    width: 750px;
  }

  ${media(992)} {
    width: 970px;
  }
  ${media(1200)} {
    width: 1170px;
  }
`

const Title = styled.span`
  display: flex;
  flex-direction: column;
  line-height: 110%;
  font-weight: 400;
  font-size: ${theme.font['3xl']};
  margin: 0 auto;

  ${media('lg')} {
    text-align: unset;
    margin: unset;
    font-size: ${theme.font['5xl']};
  }
`

const Subtitle = styled.span`
  font-size: ${theme.font['lg']};
  padding: ${theme.space[6]}px 0;
  text-align: center;
  margin: 0 auto;

  ${media('lg')} {
    text-align: unset;
    margin: unset;
    padding: ${theme.space[8]}px 0;
  }
`

const CustomButton = styled(Button)`
  color: ${theme.colors.white} !important;
  border: 2px ${theme.colors.white} solid !important;
  width: 200px;
  height: 60px !important;
  font-size: ${theme.font['3xl']} !important;
  font-weight: normal !important;
  font-family: 'Open Sans';
  text-align: center !important;
  margin: 0 auto !important;

  ${media('lg')} {
    text-align: unset !important;
    margin: unset !important;
    width: 270px;
    height: 60px !important;
  }
`

const popUpContainer = styled.div`
  position:absolute;
  bottom:230px;
  z-index:501;
  width:100%;
  max-width:400px;
  @media(max-width:1023px){
    top:230px;
    bottom:unset;
    left:50%;
    transform:translateX(-50%)
  }
  @media(max-width:325px){
    top:260px;
  }
`

HomeHero.content = Content
HomeHero.title = Title
HomeHero.subtitle = Subtitle
HomeHero.button = CustomButton
HomeHero.popUpContainer = popUpContainer

export default HomeHero
