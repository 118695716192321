import styled, { keyframes, css } from 'styled-components'
import theme, { media } from '../../helpers/theme'

const fadeIn = keyframes`
0% {
  opacity: 0.3;
}

70% {
  opacity: 0.5;

}

100% {
  opacity: 1;
}
`

const CTA = styled.div`
  width: 100%;
  background-color: ${theme.colors.white};
  position:relative;
`

CTA.animationContainer = styled.div`
  animation: ${(props) =>
    props.isInView &&
    css`
      ${fadeIn} 0.3s linear
    `};
  animation-fill-mode: forwards;
  padding-top: ${theme.space[12]}px;
  padding-bottom: ${theme.space[16]}px;
  margin-right: auto;
  margin-left: auto;
  opacity: 0;

  ${media(768)} {
    width: 750px;
  }
  ${media(992)} {
    width: 970px;
  }
  ${media(1200)} {
    width: 1170px;
  }
`

CTA.contentContainer = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  padding-top: ${theme.space[3]}px 0;
`

CTA.questionText = styled.span`
  font-size: ${theme.font['3xl']};
  flex: 2 1 0;
  color: #2b2b2b;
  padding-top: ${theme.space[2]}px;
  font-weight: 400;
  text-align: center;
  padding-right: 0;
  min-width: 100%;

  ${media('xl')} {
    padding-right: 100px;
    text-align: end;
    min-width: 0;
    white-space: nowrap;
  }
`
CTA.buttonContainer = styled.div`
  flex: 1 1 0;
  width: 100%;
  padding: 0;
  margin: 0;
  text-align: center;
  padding-top: ${theme.space[16]}px;
  ${media('xl')} {
    padding-right: 100px;
    text-align: end;
    padding-top: ${theme.space[3]}px;
  }
`

const highLightButton = keyframes`
0% {
  opacity: 1;
}

80% {
  opacity: 0.75;
}

100% {
  opacity: 0.5;
}
`

CTA.button = styled.a`
  border: none;
  margin: 0;
  border-radius: 2px;
  font-size: ${theme.font['2xl']};
  padding: ${theme.space[4]}px ${theme.space[20]}px;
  background-color: ${(props) => props.color || theme.colors.white};
  align-items: center;
  text-decoration: none;
  white-space: nowrap;
  font-weight: 400;
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.12), 0 2px 2px 0 rgba(0, 0, 0, 0.24);
  color: ${theme.colors.font.white};
  font-family: 'Open Sans';

  &:focus {
    opacity: 1;
  }

  &:hover {
    cursor: pointer;
    animation-name: ${highLightButton};
    animation-fill-mode: forwards;
  }

  &:active {
    opacity: 1;
    transform: translateY(4px);
    animation-name: ${fadeIn};
    background-color: #51964b;
  }

  ${media('xl')} {
    margin: 0 ${theme.space[8]}px;
  }
`

CTA.popupContainer = styled.div`
  position:absolute;
  bottom:120px;
  z-index:501;
  width:100%;
  max-width:400px;
  left:50%;
  transform:translateX(-50%);
  margin-left:328px;
  
  @media(max-width:1279px){
    margin-left:0;
    bottom:120px;
  }
`

export default CTA
