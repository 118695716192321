import React from 'react'
import Layout from '../components/layout'
import HomeHero from '../components/home/home-hero'
import FeatureList from '../components/feature/feature-list'
import HomeHeader from '../components/home/home-header'
import CTA from '../components/cta'
import SEO from '../components/seo'

const Home = () => {
  return (
    <Layout isWhiteLogo>
      <SEO title='Home' pageType='home' flowType='none' />
      <HomeHero />
      <HomeHeader />
      <FeatureList isHomePage />
      <CTA />
    </Layout>
  )
}

export default Home
