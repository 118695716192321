import styled from 'styled-components'
import { theme, media } from '../../../helpers/theme'

const HomeHeader = styled.div`
  background-color: ${theme.colors.background};
  padding-top: ${theme.space[5]}px;

  ${media('sm')} {
    padding: unset;
  }
`

const HomeHeaderContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: space-around;
  margin: auto;
  max-width: 620px;
  height: 280px;
  padding: 0 ${theme.space[6]}px;
  ${media('sm')} {
    padding: 0;
    height: 250px;
  }
`

const Title = styled.h1`
  font-weight: 400;
  text-align: center;
  font-size: ${theme.space['3xl']};
  color: ${theme.colors.font.black};
  padding: ${theme.space[8]}px 0;
`

const Subtitle = styled.p`
  text-align: center;
  color: ${theme.colors.font.gray};
  font-size: ${theme.font.sm};
  line-height: ${theme.font['2xl']};
  padding-bottom: ${theme.space[3]}px;
`

HomeHeader.title = Title
HomeHeader.homeHeaderContainer = HomeHeaderContainer
HomeHeader.subtitle = Subtitle
export default HomeHeader
