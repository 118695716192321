import React, { useState } from 'react'
import theme from '../../helpers/theme'
import CTA from './cta.styles'
import { DataElementLocations } from '@eig-builder/core-utils/helpers/tagging-helper'
import useIntersect from '../../helpers/hooks/useIntersect'
import PopPup from '../pop-pup'

const CTASection = () => {
  const [callToAction, setCallToAction] = useState(false)
  const [ref, entry] = useIntersect({
    threshold: 0.8,
  })
  const redirectWebdotcom = (websiteUrl) => {
    setTimeout(() => {
      document.location.href = websiteUrl
      setCallToAction(false)
  }, 5000)
  }
  return (
    <CTA>
      <CTA.animationContainer ref={ref} isInView={entry.isIntersecting}>
        <CTA.contentContainer>
          <CTA.questionText>Ready to Build Your Dream Site?</CTA.questionText>
          <CTA.buttonContainer>
            <CTA.button
              color={theme.colors.green}
              data-element-location={DataElementLocations.BODY}
              data-element-label='cta.start-building'
              data-element-id='sitey-start-building'
              onClick={() => {
                setCallToAction(true)
                redirectWebdotcom('https://www.web.com/websites/online-store-builder')
              }}
            >
              Start Building
            </CTA.button>
          </CTA.buttonContainer>
          <CTA.popupContainer>
            <PopPup callToAction={callToAction} setCallToAction={setCallToAction} top />
          </CTA.popupContainer>
        </CTA.contentContainer>
      </CTA.animationContainer>
    </CTA>
  )
}

export default CTASection
