export const FEATURE_CONTENTS = [
  {
    id: 1,
    icon: 'web',
    title: 'Intelligent Builder',
    subtitle:
      'Automatically chooses the right layout, and starts your content.',
  },
  {
    id: 2,
    icon: 'open_with',
    title: 'Fast Loading time',
    subtitle:
      'Websites are created with page speed in mind for SEO optimization.',
  },
  {
    icon: 'language',
    id: 3,
    title: 'Custom Domains',
    subtitle: 'We make it easy for you to buy or add a custom domain.',
  },
  {
    icon: 'search',
    id: 4,
    title: 'Google Friendly',
    subtitle: 'Easily add keywords so people can find you on the web.',
  },
  {
    icon: 'phone_android',
    id: 5,
    title: 'Built for Mobile',
    subtitle: 'All of our templates are optimized for mobile devices.',
  },
  {
    icon: 'store',
    id: 6,
    title: 'Set Up a Shop',
    subtitle: 'Add an e-shop to your website and start selling products.',
  },
  {
    icon: 'cloud_queue',
    id: 7,
    title: 'Reliable Hosting',
    subtitle: 'We provide you with stable and secure web hosting.',
  },
  {
    icon: 'headset_mic',
    id: 8,
    title: '24/7 Support',
    subtitle: 'Our support team is always on hand to answer your questions.',
  },
  {
    icon: 'assessment',
    id: 9,
    title: 'Google Analytics',
    subtitle: 'Analyze the experience your visitors have on your site.',
  },
  {
    icon: 'people',
    id: 10,
    title: 'Social Integration',
    subtitle: 'Link your site to social media to promote and drive traffic.',
  },
  {
    icon: 'collections',
    id: 11,
    title: 'Image Library',
    subtitle: 'Professional, categorized image library at your finger tips.',
  },
  {
    icon: 'mode_edit',
    id: 12,
    title: 'Keep a Blog',
    subtitle: 'Keep your site updated with the latest news & events.',
  },
]