import styled from 'styled-components'
import { theme, media } from '../../../helpers/theme'

const FeatureList = styled.div`
  background-color: ${(props) =>
    props.isHomePage ? theme.colors.background : 'white'};
`

const FeatureContainer = styled.div`
  display: flex;
  color: ${theme.colors.black};
  flex-wrap: wrap;
  justify-content: space-around;
  margin: auto;
  padding: ${theme.space[8]}px 0;

  ${media(768)} {
    padding-bottom: ${theme.space[8]}px;
    padding-top: 0px;
    width: 750px;
  }

  ${media(992)} {
    width: 970px;
  }
  ${media(1200)} {
    width: 1170px;
  }
`

const Feature = styled.div`
  display: flex;
  flex-direction: column;
  flex-basis: 25%;
  min-width: 300px;
  justify-content: space-between;
  margin: ${theme.space[2]}px 0;

  ${media('xl')} {
    margin: ${theme.space[6]}px;
  }

  > * {
    margin: 0 auto;
    padding: ${theme.space[1]}px ${theme.space[3]}px;
    text-align: center;
  }

  .icon {
    font-size: ${theme.font['6xl']};
    color: ${theme.colors.font.lightGray};
    width: auto;
    height: auto;
  }
`

const FeatureTitle = styled.h4`
  font-weight: normal;
  font-size: ${theme.font.xl};
  color: ${theme.colors.font.black};
`

const FeatureSubtitle = styled.p`
  color: ${theme.colors.font.gray};
  font-size: ${theme.font['sm']};
`
FeatureList.featureContainer = FeatureContainer
FeatureList.feature = Feature
FeatureList.featureTitle = FeatureTitle
FeatureList.featureSubtitle = FeatureSubtitle

export default FeatureList
