import React from 'react'
import FeatureListLayout from './feature-list.styles'
import { FEATURE_CONTENTS } from './feature-list.constants'
import Icon from '@material-ui/core/Icon'

const FeatureList = ({ isHomePage }) => (
  <FeatureListLayout isHomePage={isHomePage}>
    <FeatureListLayout.featureContainer>
      {FEATURE_CONTENTS.map((contents) => (
        <Feature title={contents.title} subtitle={contents.subtitle}>
          <Icon className='icon'>{contents.icon}</Icon>
        </Feature>
      ))}
    </FeatureListLayout.featureContainer>
  </FeatureListLayout>
)

const Feature = (props) => (
  <FeatureListLayout.feature>
    {props.children}
    <FeatureListLayout.featureTitle>
      {props.title}
    </FeatureListLayout.featureTitle>
    <FeatureListLayout.featureSubtitle>
      {props.subtitle}
    </FeatureListLayout.featureSubtitle>
  </FeatureListLayout.feature>
)

export default FeatureList
